define("ember-radio-button/templates/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hRfMDnku",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"class\",[29,[\"ember-radio-button \",[28,\"if\",[[24,[\"checked\"]],[24,[\"checkedClass\"]]],null],\" \",[22,\"joinedClassNames\"]]]],[11,\"for\",[22,\"radioId\"]],[8],[0,\"\\n    \"],[1,[28,\"radio-button-input\",null,[[\"class\",\"id\",\"autofocus\",\"disabled\",\"name\",\"required\",\"tabindex\",\"groupValue\",\"value\",\"ariaLabelledby\",\"ariaDescribedby\",\"changed\"],[[24,[\"radioClass\"]],[24,[\"radioId\"]],[24,[\"autofocus\"]],[24,[\"disabled\"]],[24,[\"name\"]],[24,[\"required\"]],[24,[\"tabindex\"]],[24,[\"groupValue\"]],[24,[\"value\"]],[24,[\"ariaLabelledby\"]],[24,[\"ariaDescribedby\"]],[28,\"action\",[[23,0,[]],\"changed\"],null]]]],false],[0,\"\\n\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"radio-button-input\",null,[[\"class\",\"id\",\"autofocus\",\"disabled\",\"name\",\"required\",\"tabindex\",\"groupValue\",\"value\",\"ariaLabelledby\",\"ariaDescribedby\",\"changed\"],[[24,[\"radioClass\"]],[24,[\"radioId\"]],[24,[\"autofocus\"]],[24,[\"disabled\"]],[24,[\"name\"]],[24,[\"required\"]],[24,[\"tabindex\"]],[24,[\"groupValue\"]],[24,[\"value\"]],[24,[\"ariaLabelledby\"]],[24,[\"ariaDescribedby\"]],[28,\"action\",[[23,0,[]],\"changed\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-radio-button/templates/components/radio-button.hbs"
    }
  });

  _exports.default = _default;
});